/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import axios from 'utils/axios';
import {
    teams,
    loading,
    hasError,
    changeTeamSelection,
    // teamMembers,
    updateTeam,
    // deleteTeamMember,
    clearError,
    rolePermissions,
    initialPermission
    // updateTeamMemberProjectsRole,
} from './teamSlice'; // Import actions from the slice
import { dispatch, getState } from 'app/store';
import errorMsgHelper from 'utils/errorMsgHelper';
import { verifiyAccessToken } from 'features/auth/authActions';
import { getMentionSettingAPI } from 'features/mention/mentionActions';
import { getAiModelsAPI } from 'features/ai-model/aiModelActions';

export const setTeamsData = (v) => () => dispatch(teams(v));
export const changingTeamSelection = (v) => () => {
    dispatch(changeTeamSelection(v));
    setTimeout(() => {
        getMentionSettingAPI()();
        getAiModelsAPI()();
        console.log('changingTeamSelection');
    }, 500);
};
export const initialPermissionSetter = (v) => () => {
    dispatch(initialPermission(v));
    setTimeout(() => {
        getMentionSettingAPI()();
        getAiModelsAPI()();
        // console.log('initialPermissionSetter');
    }, 500);
};
export const clearingError = () => () => {
    dispatch(clearError());
};
export const teamMemberToTeamAPI = async ({ selectCollaborationTeam = false }) => {
    try {
        const {
            // team: { rolePermissions },
            auth: { accessToken }
        } = getState();

        const token = await verifiyAccessToken(accessToken);

        dispatch(loading(true));
        const { data: respData } = await axios.get(`team-members/to-teams`, {
            headers: {
                Authorization: `Bearer ${token}`
                // 'x-br-team-id': selfTeam._id,
                // 'x-br-team-owner-id': selfTeam.userId
            }
        });

        setTeamsData({ ...respData, selectCollaborationTeam })();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        dispatch(loading(false));
    }
};

const setRolePermisson = (v) => () => dispatch(rolePermissions(v));

export const rolePermissionsAPI = async () => {
    try {
        // dispatch(loading(true));
        const { data: respData } = await axios.get(`role-permissions`);
        setRolePermisson(respData)();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        // dispatch(loading(false));
    }
};

// const updateProjectUserRoleData = (v) => () => dispatch(deleteTeamMember(v));

export const updateProjectUserRoleAPI = async ({
    PURP_ID,
    data = {} // , teamMemberId
}) => {
    try {
        const {
            team: { selfTeam },
            auth: { accessToken }
        } = getState();

        const token = await verifiyAccessToken(accessToken);

        // const resp =
        await axios.put(`project-user-role-permissions/${PURP_ID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selfTeam._id,
                'x-br-team-owner-id': selfTeam.userId
            }
        });

        // updateProjectUserRoleData({ ...data, PURP_ID, teamMemberId })();
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        // dispatch(loading(false));
    }
};

export const checkPermission = (
    {
        permission = '' // , projectWise = true
    },
    track
) => {
    const {
        team: {
            selectedTeam,
            myPermissions // , rolePermissions
        },
        auth: { user }
    } = getState();
    // console.log({ myPermissions }, track);
    let isPermitted = false;
    if (selectedTeam?.userId === (user._id || user.id)) {
        isPermitted = true;
        /**
     * else if (projectWise) {
        const [{ rolePermissionId }] = selectedTeam?.teamMember?.projectWiseRolePermission || [{ rolePermissionId: '' }];
        if (!rolePermissionId) return false;
        const RP = rolePermissions.find((item) => item._id === rolePermissionId).permissions || [];
        isPermitted = RP.permissions?.includes?.(permission);
    } 
     */
    } else {
        isPermitted = myPermissions.includes(permission);
        // if (track === 'createPromptBtn') {
        //     console.log(myPermissions.includes(permission), 'permission', permission);
        // }
    }
    return isPermitted;
};

const updateBasicTeamData = (v) => () => dispatch(updateTeam(v));

export const updateTeamBasicAPI = async ({ data }) => {
    try {
        const {
            team: { selfTeam },
            auth: { accessToken }
        } = getState();

        const token = await verifiyAccessToken(accessToken);

        // dispatch(loading(true));
        // const { data: respData } =
        await axios.put(`teams/${selfTeam?._id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selfTeam._id,
                'x-br-team-owner-id': selfTeam.userId
            }
        });
        updateBasicTeamData({ item: data })();
        return data;
    } catch (e) {
        // dispatch(hasError(errorMsgHelper(e)));
        // dispatch(loading(false));
        console.log(e);
    }
};
