import { Navigate, useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH, KEYWORD_PATH, MENTION_PATH, PROMPT_PATH, REPLY_PATH, REPORTS_PATH } from 'config';
import NotFoundRoute from 'views/pages/maintenance/Error';

let routeStr = '/';
export default function ThemeRoutes() {
    const { isLoading, isExpired } = useAuth();
    // const { search } = useLocation();
    // if (isTwitterAuth) routeStr = '/unauthenticated';
    if (isExpired) routeStr = '/expired';
    const authCommonRoutes = [
        { path: DASHBOARD_PATH, element: <Navigate to={routeStr} replace /> },
        { path: KEYWORD_PATH, element: <Navigate to={routeStr} replace /> },
        { path: MENTION_PATH, element: <Navigate to={routeStr} replace /> },
        { path: PROMPT_PATH, element: <Navigate to={routeStr} replace /> },
        { path: REPLY_PATH, element: <Navigate to={routeStr} replace /> },
        { path: REPORTS_PATH, element: <Navigate to={routeStr} replace /> },
        LoginRoutes,
        MainRoutes
        // NotFoundRoute
    ];
    // console.log({ search });state={search}
    // disabling auth sample reoute!
    const routes = [{ path: '/', element: <Navigate to={DASHBOARD_PATH} replace /> }, LoginRoutes, MainRoutes, NotFoundRoute];
    let allRoutes = [];
    if (isLoading) {
        allRoutes = [LoginRoutes, NotFoundRoute];
    } else if (isExpired) {
        allRoutes = [...authCommonRoutes];
    } else {
        allRoutes = routes;
    }
    return useRoutes(allRoutes);
}
