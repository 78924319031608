/* eslint-disable consistent-return */
import axios from 'utils/axios';
import { dispatch, getState } from 'app/store';
import {
    loadingCotrl,
    fetchProjects,
    hasError,
    toggleShowProjectsList,
    setSingleProjectSelectSuccess,
    addProjectLoading,
    toggleProjectCreateModal,
    addNewProject,
    updateProject,
    addKeywordForSave,
    createKeywordsLoading,
    createKeywords,
    createKeywordSuccess,
    projectCreated,
    projectRemove,
    removeKeywordForSave,
    removeCustomKeywordForSave,
    removeNegativeCustomKeywordForSave,
    addCustomKeywordForSave,
    addNegativeCustomKeywordForSave,
    selectedPlatform,
    updateProjectLoading,
    updateProjectSuccess,
    projectInit,
    clearError,
    keywordRemove,
    clearCustomKeyword,
    clearCustomNegativeKeyword,
    isEditProject,
    editProject,
    projectUpdated,
    updateMentionFetchStatusOfProject,
    createNegativeKeywordSuccess,
    negativeKeywordRemove,
    updateAdvencedSettingOfProject,
    updateAdvancedProjectSettingLoading,
    updatedAdvancedProjectSetting,
    projectRefetchingInitLoading,
    refetchInitProject,
    projectRefetchingInitialized
} from './projectSlice'; // Import actions from the slice
// import { createAsyncThunk } from '@reduxjs/toolkit';
import { subsctriptionCreditsSetter } from 'features/subscription/subscriptionActions';
import errorMsgHelper from 'utils/errorMsgHelper';
import { addingAiNewModel, deletingAiNewModel, updatedaiModelSetter } from 'features/ai-model/aiModelActions';
import { verifiyAccessToken } from 'features/auth/authActions';
import { initialPermissionSetter } from 'features/team/teamActions';

export const projectRefetchingInitializedStatus = (status) => () => {
    dispatch(projectRefetchingInitialized(status));
};
export const isEditProjectStatus =
    (v = true) =>
    () => {
        dispatch(isEditProject(v));
    };

export const updateMentionFetchStatusChangerOfProject = (v) => () => {
    dispatch(updateMentionFetchStatusOfProject(v));
};
export const editProjectSelect = (v) => () => {
    dispatch(editProject(v));
};
export const keywordRemoving = (value) => () => {
    dispatch(keywordRemove(value));
};
export const negativeKeywordRemovover = (value) => () => {
    dispatch(negativeKeywordRemove(value));
};
export const clearingCustomKeyword = () => () => {
    dispatch(clearCustomKeyword());
};
export const clearingCustomNegativeKeyword = () => () => {
    dispatch(clearCustomNegativeKeyword());
};
export const changePlatform = (platform) => () => {
    dispatch(selectedPlatform(platform));
};
export const clearingError = () => () => {
    dispatch(clearError());
};
export const projectClear = () => () => {
    dispatch(projectInit());
};
export const updaterProjectSuccess = (value) => () => {
    dispatch(updateProjectSuccess(value));
};
export const setSingleProjectSelect = (id) => () => {
    dispatch(setSingleProjectSelectSuccess({ id }));
};

export const toggleShowProjects = (v) => () => {
    dispatch(toggleShowProjectsList(v));
};

export const toggleProjectCreateModalCtrl = () => () => {
    dispatch(toggleProjectCreateModal());
};

export const projectCreatedStatus = (status) => () => {
    dispatch(projectCreated(status));
};
export const projectUpdatedStatus = (status) => () => {
    dispatch(projectUpdated(status));
};
export const projectRemoving = (id) => () => {
    dispatch(projectRemove({ id }));
};
export const createdKeywordSuccess = (value) => () => {
    dispatch(createKeywordSuccess(value));
};
export const createdNegativeKeywordSuccess = (value) => () => {
    dispatch(createNegativeKeywordSuccess(value));
};
const accessMyPermissions = () => {
    const {
        team: { rolePermissions, selectedTeam }
    } = getState();
    let myPermissions = null;
    const rolePermissionId = selectedTeam?.teamMember?.projectWiseRolePermission?.[0]?.rolePermissionId;
    if (rolePermissionId) {
        myPermissions = rolePermissions?.find?.((item) => item._id === rolePermissionId)?.permissions || [];
    } else {
        myPermissions = rolePermissions?.find?.((item) => item.name === 'owner')?.permissions || [];
    }
    return myPermissions;
};
export const getTeamProjectsAPI = () => async () => {
    try {
        const {
            auth: { accessToken },
            team: { selectedTeam }
        } = getState();
        const token = await verifiyAccessToken(accessToken);
        // if (!token) {
        //     return;
        // }
        const { _id: teamMemberId } = selectedTeam?.teamMember || {};

        if (!accessToken) {
            throw new Error('No access token found');
        }
        dispatch(loadingCotrl(true));
        const response = await axios.get(`projects`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-br-team-id': selectedTeam?._id || '',
                'x-br-team-owner-id': selectedTeam?.userId || '',
                'x-br-team-member-id': teamMemberId || ''
            }
        });
        dispatch(fetchProjects(response.data));
        // setTimeout(() => {
        console.log('initialPermissionSetter(accessMyPermissions())()');
        initialPermissionSetter(accessMyPermissions())();
        // }, 500);
    } catch (e) {
        dispatch(hasError(errorMsgHelper(e)));
        dispatch(loadingCotrl(false));
        // } finally {
        //     dispatch(loadingCotrl(false));
    }
};

export const callOthers = (v) => subsctriptionCreditsSetter(v);

export const addProjectAPI =
    ({ data = {} }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken, user }
            } = getState();
            const userId = user?._id || user?.id;
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};

            const token = await verifiyAccessToken(accessToken);

            dispatch(addProjectLoading(true));
            const response = await axios.post(`projects`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            dispatch(addNewProject(response.data));
            if (selectedTeam?.userId === userId) {
                subsctriptionCreditsSetter({ projects: -1, keywords: -(data?.suggestedKeywords?.length || 0) })();
            }
            projectCreatedStatus(true)();
            // dispatch(toggleProjectCreateModal(false));
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(addProjectLoading(false));
        }
    };

export const setUpdateProjectLoading = (v) => () => {
    dispatch(updateProjectLoading(v));
};
export const updateProjectData = (v) => () => {
    dispatch(updateProject(v));
};

export const updateProjectAPI =
    (ttt, id, data = {}) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken, user }
            } = getState();
            const token = await verifiyAccessToken(accessToken);
            const userId = user?._id || user?.id;
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};
            setUpdateProjectLoading(true)();
            const response = await axios.put(`projects/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            updateProjectData(response.data)();
            if (selectedTeam?.userId === userId) {
                subsctriptionCreditsSetter({ keywords: -(data?.suggestedKeywords?.length || 0) })();
            }
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            setUpdateProjectLoading(false)();
        }
    };

export const updatedAdvancedProjectSettingStatus = (value) => () => {
    dispatch(updatedAdvancedProjectSetting(value));
};

export const updateAdvancedSettingProjectData = (v) => () => {
    dispatch(updateAdvencedSettingOfProject(v));
};

export const updateProjectAdvancedSettingAPI =
    ({ id, data = {} }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken }
            } = getState();
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};

            const token = await verifiyAccessToken(accessToken);

            dispatch(updateAdvancedProjectSettingLoading(true));
            const { data: respData } = await axios.put(`projects/${id}/advanced`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            updateAdvancedSettingProjectData(respData)();
            if (respData?.model) {
                const { actionType } = respData?.model;
                if (actionType === 'add') {
                    addingAiNewModel({ item: respData?.model })();
                } else if (actionType === 'update') {
                    updatedaiModelSetter({ item: respData?.model })();
                } else if (actionType === 'delete') {
                    deletingAiNewModel({ item: respData?.model })();
                }
            }
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(updateAdvancedProjectSettingLoading(false));
        }
    };

export const addingKeywords = (data) => () => {
    dispatch(createKeywords(data));
};

export const createKeywordsApi =
    (data = {}) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken, user }
            } = getState();
            const token = await verifiyAccessToken(accessToken);
            const userId = user?._id || user?.id;
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};
            dispatch(createKeywordsLoading(true));
            const response = await axios.post(`keywords`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            const keywords = data?.suggestedKeywords?.length || 0;
            if (selectedTeam?.userId === userId) {
                subsctriptionCreditsSetter({ keywords: -keywords })();
            }
            // createdKeywordSuccess(true)();
            addingKeywords(response.data)();
            // setTimeout(() => {
            // }, 2000);
            return response.data;
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(createKeywordsLoading(false));
        }
    };

export const deleteKeywordAPI =
    ({ id, projectId }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken, user }
            } = getState();
            const userId = user?._id || user.id;
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};

            const token = await verifiyAccessToken(accessToken);
            await axios.delete(`keywords/${id}/project/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            if (selectedTeam?.userId === userId) {
                subsctriptionCreditsSetter({ keywords: 1 })();
            }
            keywordRemoving({ id })();
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
        }
    };

export const deleteNegativeKeywordAPI =
    ({ id, keyword }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken }
            } = getState();
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};
            const token = await verifiyAccessToken(accessToken);
            await axios.delete(`projects/${id}/negative-keyword/${keyword}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            negativeKeywordRemovover({ keyword, _id: id })();
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
        }
    };

export const addingKeywordForSave =
    (keyword = '', index) =>
    () => {
        dispatch(addKeywordForSave({ keyword, index }));
    };

export const addingCustomKeywordForSave =
    (keyword = '', index) =>
    () => {
        dispatch(addCustomKeywordForSave({ keyword, index }));
    };
export const addingNegativeCustomKeywordForSave =
    (keyword = '', index) =>
    () => {
        dispatch(addNegativeCustomKeywordForSave({ keyword, index }));
    };
export const removingKeywordForSave =
    (keyword = '') =>
    () => {
        dispatch(removeKeywordForSave(keyword));
    };
export const removingCustomKeywordForSave =
    (index = '') =>
    () => {
        dispatch(removeCustomKeywordForSave(index));
    };

export const removingNegativeCustomKeywordForSave =
    (index = '') =>
    () => {
        dispatch(removeNegativeCustomKeywordForSave(index));
    };

export const refetchedInitProjectData = (v) => () => {
    dispatch(refetchInitProject(v));
};

export const refetchingProjectAPI =
    ({ id, data = {} }) =>
    async () => {
        try {
            const {
                team: { selectedTeam },
                auth: { accessToken }
            } = getState();
            const { _id: teamMemberId } = selectedTeam?.teamMember || {};

            const token = await verifiyAccessToken(accessToken);

            dispatch(projectRefetchingInitLoading(true));
            const { data: respData } = await axios.post(`projects/${id}/instant-fetch`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-br-team-id': selectedTeam?._id || '',
                    'x-br-team-owner-id': selectedTeam?.userId || '',
                    'x-br-team-member-id': teamMemberId || ''
                }
            });
            refetchedInitProjectData(respData)();
        } catch (e) {
            dispatch(hasError(errorMsgHelper(e)));
            dispatch(projectRefetchingInitLoading(false));
        }
    };

// export const fetchAllProjects = createAsyncThunk('project/fetchAllProjects', async (_, { getState, dispatch }) => {
//     try {
//         const {
//             auth: { accessToken, user }
//         } = getState();

//         const token = await verifiyAccessToken(accessToken);
//         // if (!token) {
//         //     return;
//         // }
//         const response = await axios.get(`projects/${user._id}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         });
//         return response.data;
//     } catch (e) {
//         dispatch(hasError(errorMsgHelper(e)));
//         throw e;
//     }
// });

export const domainCheckerAPI = async ({ data = {} }) => {
    const resp = await axios.post(`projects/domain-checker`, data);
    return resp.data;
};

export const deleteProjectAPI = async ({ id }) => {
    const {
        team: { selectedTeam },
        auth: { accessToken, user }
    } = getState();
    const { _id: teamMemberId } = selectedTeam?.teamMember || {};

    const token = await verifiyAccessToken(accessToken);
    const userId = user?._id || user?.id;

    const { data: respData } = await axios.delete(`projects/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'x-br-team-id': selectedTeam?._id || '',
            'x-br-team-owner-id': selectedTeam?.userId || '',
            'x-br-team-member-id': teamMemberId || ''
        }
    });

    projectRemoving(id)();
    if (selectedTeam?.userId === userId) {
        subsctriptionCreditsSetter({ projects: 1, keywords: respData?.deletedKeywords })();
    }
    return respData;
};
