/* eslint-disable import/prefer-default-export */
const reddit = 'platforms/reddit.png';
const linkedin = 'platforms/linkedin.png';
const quora = 'platforms/quora.png';
const twitter = 'platforms/twitter.png';
const facebook = 'platforms/facebook.png';
const instagram = 'platforms/instagram.png';
const pinterest = 'platforms/pinterest.png';
const tiktok = 'platforms/tiktok.png';
const youtube = 'platforms/youtube.svg';

export const platformsSrc = {
    'reddit.com': reddit,
    'linkedin.com': linkedin,
    'quora.com': quora,
    'twitter.com': twitter,
    'facebook.com': facebook,
    'instagram.com': instagram,
    'pinterest.com': pinterest,
    'tiktok.com': tiktok,
    'youtube.com': youtube
};

export const keywordColors = {
    1: {
        background: '#C7FCEB',
        color: '#0A362E'
    },
    2: {
        background: '#CDF2FA',
        color: '#0F3A4D'
    },
    3: {
        background: '#FFF1CD',
        color: '#3B2109'
    }
};

export const OPEN_AI_MODELS = [
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-1106',
    // 'gpt-3.5-turbo-instruct',
    //
    'gpt-4-turbo',
    'gpt-4-turbo-2024-04-09',
    'gpt-4-turbo-preview',
    'gpt-4-0125-preview',
    'gpt-4-1106-preview',
    'gpt-4',
    'gpt-4-0613',
    // 'gpt-4-0314',
    //
    'gpt-4o-mini',
    'gpt-4o-mini-2024-07-18',
    'gpt-4o',
    //
    'gpt-4o-2024-05-13',
    'gpt-4o-2024-08-06',
    'chatgpt-4o-latest'
];
export const GEMINI_MODELS = ['gemini-1.5-flash-latest', 'gemini-1.5-flash', 'gemini-1.0-pro', 'gemini-1.5-pro'];
// const geminiModels = ['gemini-1.5-flash', 'gemini-1.5', 'gemini', 'gemini-ultra', 'gemini-advanced'];
export const STRAICO_MODELS = [
    'anthropic/claude-3-haiku:beta',
    'anthropic/claude-3-opus',
    'anthropic/claude-3-sonnet',
    'anthropic/claude-3.5-sonnet',
    'cohere/command-r-08-2024',
    'cohere/command-r-plus-08-2024',
    'cognitivecomputations/dolphin-mixtral-8x7b',
    'alpindale/goliath-120b',
    'google/gemini-pro-1.5',
    'google/gemma-2-27b-it',
    'gryphe/mythomax-l2-13b',
    'meta-llama/llama-3-70b-instruct:nitro',
    'meta-llama/llama-3-8b-instruct',
    'meta-llama/llama-3.1-405b-instruct',
    'meta-llama/llama-3.1-70b-instruct',
    'mistralai/codestral-mamba',
    'mistralai/mistral-large',
    'mistralai/mixtral-8x7b-instruct',
    'nousresearch/hermes-3-llama-3.1-405b',
    'openai/gpt-3.5-turbo-0125',
    'openai/gpt-4',
    'openai/gpt-4-turbo-2024-04-09',
    'openai/gpt-4-vision-preview',
    'openai/gpt-4o-2024-08-06',
    'openai/gpt-4o',
    'openai/gpt-4o-mini',
    'perplexity/llama-3.1-sonar-huge-128k-online',
    // 'perplexity/llama-3-sonar-large-32k-online',
    // '    /llama-3-sonar-small-32k-online',
    'qwen/qwen-2-72b-instruct'
    // 'mattshumer/reflection-70b'
];

export const CLAUDE_ROUTER_MODELS = [
    {
        model: 'claude-3-5-sonnet-20241022',
        label: 'Claude 3.5 Sonnet (New)'
        // created_at: '2024-10-22T00:00:00Z',
        // max_context_window: 200000,
        // max_response_tokens: 4096
    },
    {
        model: 'claude-3-5-haiku-20241022',
        label: 'Claude 3.5 Haiku'
        // created_at: '2024-10-22T00:00:00Z',
        // max_context_window: 200000,
        // max_response_tokens: 4096
    },
    {
        model: 'claude-3-5-sonnet-20240620',
        label: 'Claude 3.5 Sonnet (Old)'
        // created_at: '2024-06-20T00:00:00Z',
        // max_context_window: 200000,
        // max_response_tokens: 4096
    },
    {
        model: 'claude-3-haiku-20240307',
        label: 'Claude 3 Haiku'
        // created_at: '2024-03-07T00:00:00Z',
        // max_context_window: 200000,
        // max_response_tokens: 4096
    },
    {
        model: 'claude-3-opus-20240229',
        label: 'Claude 3 Opus'
        // created_at: '2024-02-29T00:00:00Z',
        // max_context_window: 200000,
        // max_response_tokens: 4096
    }
];

export const OPEN_ROUTER_MODELS = [
    { label: 'Qwen: Qwen-Turbo', model: 'qwen/qwen-turbo' },
    { label: 'Qwen: Qwen-Plus', model: 'qwen/qwen-plus' },
    { label: 'Qwen: Qwen-Max ', model: 'qwen/qwen-max' },
    // { label: 'OpenAI: o3 Mini', model: 'openai/o3-mini' },
    {
        label: 'Deepseek: Deepseek R1 Distill Qwen 1.5B',
        model: 'deepseek/deepseek-r1-distill-qwen-1.5b'
    },
    {
        label: 'Mistral: Mistral Small 3',
        model: 'mistralai/mistral-small-24b-instruct-2501'
    },
    {
        label: 'DeepSeek: DeepSeek R1 Distill Qwen 32B',
        model: 'deepseek/deepseek-r1-distill-qwen-32b'
    },
    {
        label: 'DeepSeek: DeepSeek R1 Distill Qwen 14B',
        model: 'deepseek/deepseek-r1-distill-qwen-14b'
    },
    { label: 'Perplexity: Sonar Reasoning', model: 'perplexity/sonar-reasoning' },
    { label: 'Perplexity: Sonar', model: 'perplexity/sonar' },
    { label: 'Liquid: LFM 7B', model: 'liquid/lfm-7b' },
    { label: 'Liquid: LFM 3B', model: 'liquid/lfm-3b' },
    {
        label: 'DeepSeek: DeepSeek R1 Distill Llama 70B (free)',
        model: 'deepseek/deepseek-r1-distill-llama-70b:free'
    },
    {
        label: 'DeepSeek: DeepSeek R1 Distill Llama 70B',
        model: 'deepseek/deepseek-r1-distill-llama-70b'
    },
    {
        label: 'Google: Gemini 2.0 Flash Thinking Experimental 01-21 (free)',
        model: 'google/gemini-2.0-flash-thinking-exp:free'
    },
    { label: 'DeepSeek: DeepSeek R1 (free)', model: 'deepseek/deepseek-r1:free' },
    { label: 'DeepSeek: DeepSeek R1', model: 'deepseek/deepseek-r1' },
    {
        label: 'DeepSeek: DeepSeek R1 (nitro)',
        model: 'deepseek/deepseek-r1:nitro'
    },
    {
        label: 'Rogue Rose 103B v0.2 (free)',
        model: 'sophosympatheia/rogue-rose-103b-v0.2:free'
    },
    { label: 'MiniMax: MiniMax-01', model: 'minimax/minimax-01' },
    { label: 'Mistral: Codestral 2501', model: 'mistralai/codestral-2501' },
    { label: 'Microsoft: Phi 4', model: 'microsoft/phi-4' },
    {
        label: 'Sao10K: Llama 3.1 70B Hanami x1',
        model: 'sao10k/l3.1-70b-hanami-x1'
    },
    { label: 'DeepSeek: DeepSeek V3', model: 'deepseek/deepseek-chat' },
    { label: 'Qwen: QvQ 72B Preview', model: 'qwen/qvq-72b-preview' },
    {
        label: 'Google: Gemini 2.0 Flash Thinking Experimental (free)',
        model: 'google/gemini-2.0-flash-thinking-exp-1219:free'
    },
    { label: 'Sao10K: Llama 3.3 Euryale 70B', model: 'sao10k/l3.3-euryale-70b' },
    // { label: 'OpenAI: o1', model: 'openai/o1' },
    { label: 'EVA Llama 3.33 70b', model: 'eva-unit-01/eva-llama-3.33-70b' },
    { label: 'xAI: Grok 2 Vision 1212', model: 'x-ai/grok-2-vision-1212' },
    { label: 'xAI: Grok 2 1212', model: 'x-ai/grok-2-1212' },
    {
        label: 'Cohere: Command R7B (12-2024)',
        model: 'cohere/command-r7b-12-2024'
    },
    {
        label: 'Google: Gemini Flash 2.0 Experimental (free)',
        model: 'google/gemini-2.0-flash-exp:free'
    },
    {
        label: 'Google: Gemini Experimental 1206 (free)',
        model: 'google/gemini-exp-1206:free'
    },
    {
        label: 'Meta: Llama 3.3 70B Instruct',
        model: 'meta-llama/llama-3.3-70b-instruct'
    },
    { label: 'Amazon: Nova Lite 1.0', model: 'amazon/nova-lite-v1' },
    { label: 'Amazon: Nova Micro 1.0', model: 'amazon/nova-micro-v1' },
    { label: 'Amazon: Nova Pro 1.0', model: 'amazon/nova-pro-v1' },
    { label: 'Qwen: QwQ 32B Preview', model: 'qwen/qwq-32b-preview' },
    {
        label: 'Google: Gemini Experimental 1121 (free)',
        model: 'google/gemini-exp-1121:free'
    },
    {
        label: 'Google: LearnLM 1.5 Pro Experimental (free)',
        model: 'google/learnlm-1.5-pro-experimental:free'
    },
    { label: 'EVA Qwen2.5 72B', model: 'eva-unit-01/eva-qwen-2.5-72b' },
    { label: 'OpenAI: GPT-4o (2024-11-20)', model: 'openai/gpt-4o-2024-11-20' },
    { label: 'Mistral Large 2411', model: 'mistralai/mistral-large-2411' },
    { label: 'Mistral Large 2407', model: 'mistralai/mistral-large-2407' },
    {
        label: 'Mistral: Pixtral Large 2411',
        model: 'mistralai/pixtral-large-2411'
    },
    { label: 'xAI: Grok Vision Beta', model: 'x-ai/grok-vision-beta' },
    {
        label: 'Google: Gemini Experimental 1114 (free)',
        model: 'google/gemini-exp-1114:free'
    },
    {
        label: 'Infermatic: Mistral Nemo Inferor 12B',
        model: 'infermatic/mn-inferor-12b'
    },
    {
        label: 'Qwen2.5 Coder 32B Instruct',
        model: 'qwen/qwen-2.5-coder-32b-instruct'
    },
    { label: 'SorcererLM 8x22B', model: 'raifle/sorcererlm-8x22b' },
    { label: 'EVA Qwen2.5 32B', model: 'eva-unit-01/eva-qwen-2.5-32b' },
    { label: 'Unslopnemo 12b', model: 'thedrummer/unslopnemo-12b' },
    {
        label: 'Anthropic: Claude 3.5 Haiku (2024-10-22) (self-moderated)',
        model: 'anthropic/claude-3.5-haiku-20241022:beta'
    },
    {
        label: 'Anthropic: Claude 3.5 Haiku (2024-10-22)',
        model: 'anthropic/claude-3.5-haiku-20241022'
    },
    {
        label: 'Anthropic: Claude 3.5 Haiku (self-moderated)',
        model: 'anthropic/claude-3.5-haiku:beta'
    },
    { label: 'Anthropic: Claude 3.5 Haiku', model: 'anthropic/claude-3.5-haiku' },
    {
        label: 'NeverSleep: Lumimaid v0.2 70B',
        model: 'neversleep/llama-3.1-lumimaid-70b'
    },
    { label: 'Magnum v4 72B', model: 'anthracite-org/magnum-v4-72b' },
    {
        label: 'Anthropic: Claude 3.5 Sonnet (self-moderated)',
        model: 'anthropic/claude-3.5-sonnet:beta'
    },
    {
        label: 'Anthropic: Claude 3.5 Sonnet',
        model: 'anthropic/claude-3.5-sonnet'
    },
    { label: 'xAI: Grok Beta', model: 'x-ai/grok-beta' },
    { label: 'Mistral: Ministral 8B', model: 'mistralai/ministral-8b' },
    { label: 'Mistral: Ministral 3B', model: 'mistralai/ministral-3b' },
    { label: 'Qwen2.5 7B Instruct', model: 'qwen/qwen-2.5-7b-instruct' },
    {
        label: 'NVIDIA: Llama 3.1 Nemotron 70B Instruct (free)',
        model: 'nvidia/llama-3.1-nemotron-70b-instruct:free'
    },
    {
        label: 'NVIDIA: Llama 3.1 Nemotron 70B Instruct',
        model: 'nvidia/llama-3.1-nemotron-70b-instruct'
    },
    { label: 'Inflection: Inflection 3 Pi', model: 'inflection/inflection-3-pi' },
    {
        label: 'Inflection: Inflection 3 Productivity',
        model: 'inflection/inflection-3-productivity'
    },
    { label: 'Google: Gemini Flash 1.5 8B', model: 'google/gemini-flash-1.5-8b' },
    { label: 'Magnum v2 72B', model: 'anthracite-org/magnum-v2-72b' },
    { label: 'Liquid: LFM 40B MoE', model: 'liquid/lfm-40b' },
    { label: 'Rocinante 12B', model: 'thedrummer/rocinante-12b' },
    {
        label: 'Meta: Llama 3.2 3B Instruct (free)',
        model: 'meta-llama/llama-3.2-3b-instruct:free'
    },
    {
        label: 'Meta: Llama 3.2 3B Instruct',
        model: 'meta-llama/llama-3.2-3b-instruct'
    },
    {
        label: 'Meta: Llama 3.2 1B Instruct (free)',
        model: 'meta-llama/llama-3.2-1b-instruct:free'
    },
    {
        label: 'Meta: Llama 3.2 1B Instruct',
        model: 'meta-llama/llama-3.2-1b-instruct'
    },
    {
        label: 'Meta: Llama 3.2 90B Vision Instruct (free)',
        model: 'meta-llama/llama-3.2-90b-vision-instruct:free'
    },
    {
        label: 'Meta: Llama 3.2 90B Vision Instruct',
        model: 'meta-llama/llama-3.2-90b-vision-instruct'
    },
    {
        label: 'Meta: Llama 3.2 11B Vision Instruct (free)',
        model: 'meta-llama/llama-3.2-11b-vision-instruct:free'
    },
    {
        label: 'Meta: Llama 3.2 11B Vision Instruct',
        model: 'meta-llama/llama-3.2-11b-vision-instruct'
    },
    { label: 'Qwen2.5 72B Instruct', model: 'qwen/qwen-2.5-72b-instruct' },
    { label: 'Qwen2-VL 72B Instruct', model: 'qwen/qwen-2-vl-72b-instruct' },
    {
        label: 'NeverSleep: Lumimaid v0.2 8B',
        model: 'neversleep/llama-3.1-lumimaid-8b'
    },
    { label: 'OpenAI: o1-mini (2024-09-12)', model: 'openai/o1-mini-2024-09-12' },
    { label: 'OpenAI: o1-preview', model: 'openai/o1-preview' },
    {
        label: 'OpenAI: o1-preview (2024-09-12)',
        model: 'openai/o1-preview-2024-09-12'
    },
    { label: 'OpenAI: o1-mini', model: 'openai/o1-mini' },
    { label: 'Mistral: Pixtral 12B', model: 'mistralai/pixtral-12b' },
    { label: 'Cohere: Command R (08-2024)', model: 'cohere/command-r-08-2024' },
    {
        label: 'Cohere: Command R+ (08-2024)',
        model: 'cohere/command-r-plus-08-2024'
    },
    { label: 'Qwen2-VL 7B Instruct', model: 'qwen/qwen-2-vl-7b-instruct' },
    {
        label: 'Google: Gemini Flash 1.5 Experimental (free)',
        model: 'google/gemini-flash-1.5-exp:free'
    },
    {
        label: 'Google: Gemini Flash 1.5 Experimental',
        model: 'google/gemini-flash-1.5-exp'
    },
    {
        label: 'Sao10K: Llama 3.1 Euryale 70B v2.2',
        model: 'sao10k/l3.1-euryale-70b'
    },
    {
        label: 'Google: Gemini Flash 1.5 8B Experimental',
        model: 'google/gemini-flash-1.5-8b-exp'
    },
    { label: 'AI21: Jamba 1.5 Large', model: 'ai21/jamba-1-5-large' },
    { label: 'AI21: Jamba 1.5 Mini', model: 'ai21/jamba-1-5-mini' },
    {
        label: 'Microsoft: Phi-3.5 Mini 128K Instruct',
        model: 'microsoft/phi-3.5-mini-128k-instruct'
    },
    {
        label: 'Nous: Hermes 3 70B Instruct',
        model: 'nousresearch/hermes-3-llama-3.1-70b'
    },
    {
        label: 'Nous: Hermes 3 405B Instruct',
        model: 'nousresearch/hermes-3-llama-3.1-405b'
    },
    {
        label: 'Perplexity: Llama 3.1 Sonar 405B Online',
        model: 'perplexity/llama-3.1-sonar-huge-128k-online'
    },
    { label: 'OpenAI: ChatGPT-4o', model: 'openai/chatgpt-4o-latest' },
    { label: 'Sao10K: Llama 3 8B Lunaris', model: 'sao10k/l3-lunaris-8b' },
    {
        label: 'Aetherwiing: Starcannon 12B',
        model: 'aetherwiing/mn-starcannon-12b'
    },
    { label: 'OpenAI: GPT-4o (2024-08-06)', model: 'openai/gpt-4o-2024-08-06' },
    { label: 'Meta: Llama 3.1 405B (base)', model: 'meta-llama/llama-3.1-405b' },
    { label: 'Mistral Nemo 12B Celeste', model: 'nothingiisreal/mn-celeste-12b' },
    {
        label: 'Perplexity: Llama 3.1 Sonar 8B',
        model: 'perplexity/llama-3.1-sonar-small-128k-chat'
    },
    {
        label: 'Google: Gemini Pro 1.5 Experimental',
        model: 'google/gemini-pro-1.5-exp'
    },
    {
        label: 'Perplexity: Llama 3.1 Sonar 70B',
        model: 'perplexity/llama-3.1-sonar-large-128k-chat'
    },
    {
        label: 'Perplexity: Llama 3.1 Sonar 70B Online',
        model: 'perplexity/llama-3.1-sonar-large-128k-online'
    },
    {
        label: 'Perplexity: Llama 3.1 Sonar 8B Online',
        model: 'perplexity/llama-3.1-sonar-small-128k-online'
    },
    {
        label: 'Meta: Llama 3.1 405B Instruct (free)',
        model: 'meta-llama/llama-3.1-405b-instruct:free'
    },
    {
        label: 'Meta: Llama 3.1 405B Instruct',
        model: 'meta-llama/llama-3.1-405b-instruct'
    },
    {
        label: 'Meta: Llama 3.1 405B Instruct (nitro)',
        model: 'meta-llama/llama-3.1-405b-instruct:nitro'
    },
    {
        label: 'Meta: Llama 3.1 8B Instruct (free)',
        model: 'meta-llama/llama-3.1-8b-instruct:free'
    },
    {
        label: 'Meta: Llama 3.1 8B Instruct',
        model: 'meta-llama/llama-3.1-8b-instruct'
    },
    {
        label: 'Meta: Llama 3.1 8B Instruct (nitro)',
        model: 'meta-llama/llama-3.1-8b-instruct:nitro'
    },
    {
        label: 'Meta: Llama 3.1 70B Instruct (free)',
        model: 'meta-llama/llama-3.1-70b-instruct:free'
    },
    {
        label: 'Meta: Llama 3.1 70B Instruct',
        model: 'meta-llama/llama-3.1-70b-instruct'
    },
    {
        label: 'Meta: Llama 3.1 70B Instruct (nitro)',
        model: 'meta-llama/llama-3.1-70b-instruct:nitro'
    },
    { label: 'Mistral: Mistral Nemo', model: 'mistralai/mistral-nemo' },
    { label: 'Mistral: Codestral Mamba', model: 'mistralai/codestral-mamba' },
    { label: 'OpenAI: GPT-4o-mini', model: 'openai/gpt-4o-mini' },
    {
        label: 'OpenAI: GPT-4o-mini (2024-07-18)',
        model: 'openai/gpt-4o-mini-2024-07-18'
    },
    { label: 'Qwen 2 7B Instruct (free)', model: 'qwen/qwen-2-7b-instruct:free' },
    { label: 'Qwen 2 7B Instruct', model: 'qwen/qwen-2-7b-instruct' },
    { label: 'Google: Gemma 2 27B', model: 'google/gemma-2-27b-it' },
    { label: 'Magnum 72B', model: 'alpindale/magnum-72b' },
    { label: 'Google: Gemma 2 9B (free)', model: 'google/gemma-2-9b-it:free' },
    { label: 'Google: Gemma 2 9B', model: 'google/gemma-2-9b-it' },
    { label: '01.AI: Yi Large', model: '01-ai/yi-large' },
    { label: 'AI21: Jamba Instruct', model: 'ai21/jamba-instruct' },
    {
        label: 'Anthropic: Claude 3.5 Sonnet (2024-06-20) (self-moderated)',
        model: 'anthropic/claude-3.5-sonnet-20240620:beta'
    },
    {
        label: 'Anthropic: Claude 3.5 Sonnet (2024-06-20)',
        model: 'anthropic/claude-3.5-sonnet-20240620'
    },
    { label: 'Sao10k: Llama 3 Euryale 70B v2.1', model: 'sao10k/l3-euryale-70b' },
    {
        label: 'Dolphin 2.9.2 Mixtral 8x22B 🐬',
        model: 'cognitivecomputations/dolphin-mixtral-8x22b'
    },
    { label: 'Qwen 2 72B Instruct', model: 'qwen/qwen-2-72b-instruct' },
    {
        label: 'Mistral: Mistral 7B Instruct (free)',
        model: 'mistralai/mistral-7b-instruct:free'
    },
    {
        label: 'Mistral: Mistral 7B Instruct',
        model: 'mistralai/mistral-7b-instruct'
    },
    {
        label: 'Mistral: Mistral 7B Instruct (nitro)',
        model: 'mistralai/mistral-7b-instruct:nitro'
    },
    {
        label: 'Mistral: Mistral 7B Instruct v0.3',
        model: 'mistralai/mistral-7b-instruct-v0.3'
    },
    {
        label: 'NousResearch: Hermes 2 Pro - Llama-3 8B',
        model: 'nousresearch/hermes-2-pro-llama-3-8b'
    },
    {
        label: 'Microsoft: Phi-3 Mini 128K Instruct (free)',
        model: 'microsoft/phi-3-mini-128k-instruct:free'
    },
    {
        label: 'Microsoft: Phi-3 Mini 128K Instruct',
        model: 'microsoft/phi-3-mini-128k-instruct'
    },
    {
        label: 'Microsoft: Phi-3 Medium 128K Instruct (free)',
        model: 'microsoft/phi-3-medium-128k-instruct:free'
    },
    {
        label: 'Microsoft: Phi-3 Medium 128K Instruct',
        model: 'microsoft/phi-3-medium-128k-instruct'
    },
    {
        label: 'NeverSleep: Llama 3 Lumimaid 70B',
        model: 'neversleep/llama-3-lumimaid-70b'
    },
    { label: 'Google: Gemini Flash 1.5', model: 'google/gemini-flash-1.5' },
    { label: 'DeepSeek V2.5', model: 'deepseek/deepseek-chat-v2.5' },
    { label: 'OpenAI: GPT-4o (2024-05-13)', model: 'openai/gpt-4o-2024-05-13' },
    { label: 'Meta: LlamaGuard 2 8B', model: 'meta-llama/llama-guard-2-8b' },
    { label: 'OpenAI: GPT-4o', model: 'openai/gpt-4o' },
    { label: 'OpenAI: GPT-4o (extended)', model: 'openai/gpt-4o:extended' },
    {
        label: 'NeverSleep: Llama 3 Lumimaid 8B (extended)',
        model: 'neversleep/llama-3-lumimaid-8b:extended'
    },
    {
        label: 'NeverSleep: Llama 3 Lumimaid 8B',
        model: 'neversleep/llama-3-lumimaid-8b'
    },
    {
        label: 'Meta: Llama 3 8B Instruct (free)',
        model: 'meta-llama/llama-3-8b-instruct:free'
    },
    {
        label: 'Meta: Llama 3 8B Instruct',
        model: 'meta-llama/llama-3-8b-instruct'
    },
    {
        label: 'Meta: Llama 3 8B Instruct (extended)',
        model: 'meta-llama/llama-3-8b-instruct:extended'
    },
    {
        label: 'Meta: Llama 3 8B Instruct (nitro)',
        model: 'meta-llama/llama-3-8b-instruct:nitro'
    },
    {
        label: 'Meta: Llama 3 70B Instruct',
        model: 'meta-llama/llama-3-70b-instruct'
    },
    {
        label: 'Meta: Llama 3 70B Instruct (nitro)',
        model: 'meta-llama/llama-3-70b-instruct:nitro'
    },
    {
        label: 'Mistral: Mixtral 8x22B Instruct',
        model: 'mistralai/mixtral-8x22b-instruct'
    },
    { label: 'WizardLM-2 8x22B', model: 'microsoft/wizardlm-2-8x22b' },
    { label: 'WizardLM-2 7B', model: 'microsoft/wizardlm-2-7b' },
    { label: 'Google: Gemini Pro 1.5', model: 'google/gemini-pro-1.5' },
    { label: 'OpenAI: GPT-4 Turbo', model: 'openai/gpt-4-turbo' },
    { label: 'Cohere: Command R+', model: 'cohere/command-r-plus' },
    {
        label: 'Cohere: Command R+ (04-2024)',
        model: 'cohere/command-r-plus-04-2024'
    },
    { label: 'Databricks: DBRX 132B Instruct', model: 'databricks/dbrx-instruct' },
    { label: 'Midnight Rose 70B', model: 'sophosympatheia/midnight-rose-70b' },
    { label: 'Cohere: Command', model: 'cohere/command' },
    { label: 'Cohere: Command R', model: 'cohere/command-r' },
    {
        label: 'Anthropic: Claude 3 Haiku (self-moderated)',
        model: 'anthropic/claude-3-haiku:beta'
    },
    { label: 'Anthropic: Claude 3 Haiku', model: 'anthropic/claude-3-haiku' },
    {
        label: 'Anthropic: Claude 3 Opus (self-moderated)',
        model: 'anthropic/claude-3-opus:beta'
    },
    { label: 'Anthropic: Claude 3 Opus', model: 'anthropic/claude-3-opus' },
    {
        label: 'Anthropic: Claude 3 Sonnet (self-moderated)',
        model: 'anthropic/claude-3-sonnet:beta'
    },
    { label: 'Anthropic: Claude 3 Sonnet', model: 'anthropic/claude-3-sonnet' },
    { label: 'Cohere: Command R (03-2024)', model: 'cohere/command-r-03-2024' },
    { label: 'Mistral Large', model: 'mistralai/mistral-large' },
    { label: 'Google: Gemma 7B', model: 'google/gemma-7b-it' },
    {
        label: 'OpenAI: GPT-3.5 Turbo (older v0613)',
        model: 'openai/gpt-3.5-turbo-0613'
    },
    { label: 'OpenAI: GPT-4 Turbo Preview', model: 'openai/gpt-4-turbo-preview' },
    {
        label: 'Nous: Hermes 2 Mixtral 8x7B DPO',
        model: 'nousresearch/nous-hermes-2-mixtral-8x7b-dpo'
    },
    { label: 'Mistral Small', model: 'mistralai/mistral-small' },
    { label: 'Mistral Tiny', model: 'mistralai/mistral-tiny' },
    { label: 'Mistral Medium', model: 'mistralai/mistral-medium' },
    {
        label: 'Dolphin 2.6 Mixtral 8x7B 🐬',
        model: 'cognitivecomputations/dolphin-mixtral-8x7b'
    },
    { label: 'Google: Gemini Pro Vision 1.0', model: 'google/gemini-pro-vision' },
    { label: 'Google: Gemini Pro 1.0', model: 'google/gemini-pro' },
    { label: 'Mistral: Mixtral 8x7B (base)', model: 'mistralai/mixtral-8x7b' },
    {
        label: 'Mistral: Mixtral 8x7B Instruct',
        model: 'mistralai/mixtral-8x7b-instruct'
    },
    {
        label: 'Mistral: Mixtral 8x7B Instruct (nitro)',
        model: 'mistralai/mixtral-8x7b-instruct:nitro'
    },
    { label: 'OpenChat 3.5 7B (free)', model: 'openchat/openchat-7b:free' },
    { label: 'OpenChat 3.5 7B', model: 'openchat/openchat-7b' },
    { label: 'Noromaid 20B', model: 'neversleep/noromaid-20b' },
    {
        label: 'Anthropic: Claude v2 (self-moderated)',
        model: 'anthropic/claude-2:beta'
    },
    { label: 'Anthropic: Claude v2', model: 'anthropic/claude-2' },
    {
        label: 'Anthropic: Claude v2.1 (self-moderated)',
        model: 'anthropic/claude-2.1:beta'
    },
    { label: 'Anthropic: Claude v2.1', model: 'anthropic/claude-2.1' },
    {
        label: 'OpenHermes 2.5 Mistral 7B',
        model: 'teknium/openhermes-2.5-mistral-7b'
    },
    { label: 'Toppy M 7B (free)', model: 'undi95/toppy-m-7b:free' },
    { label: 'Toppy M 7B (nitro)', model: 'undi95/toppy-m-7b:nitro' },
    { label: 'Toppy M 7B', model: 'undi95/toppy-m-7b' },
    { label: 'Goliath 120B', model: 'alpindale/goliath-120b' },
    { label: 'Auto Router', model: 'openrouter/auto' },
    {
        label: 'OpenAI: GPT-3.5 Turbo 16k (older v1106)',
        model: 'openai/gpt-3.5-turbo-1106'
    },
    {
        label: 'OpenAI: GPT-4 Turbo (older v1106)',
        model: 'openai/gpt-4-1106-preview'
    },
    { label: 'Google: PaLM 2 Chat 32k', model: 'google/palm-2-chat-bison-32k' },
    {
        label: 'Google: PaLM 2 Code Chat 32k',
        model: 'google/palm-2-codechat-bison-32k'
    },
    { label: 'Airoboros 70B', model: 'jondurbin/airoboros-l2-70b' },
    { label: 'Xwin 70B', model: 'xwin-lm/xwin-lm-70b' },
    {
        label: 'OpenAI: GPT-3.5 Turbo Instruct',
        model: 'openai/gpt-3.5-turbo-instruct'
    },
    {
        label: 'Mistral: Mistral 7B Instruct v0.1',
        model: 'mistralai/mistral-7b-instruct-v0.1'
    },
    { label: 'Pygmalion: Mythalion 13B', model: 'pygmalionai/mythalion-13b' },
    { label: 'OpenAI: GPT-3.5 Turbo 16k', model: 'openai/gpt-3.5-turbo-16k' },
    { label: 'OpenAI: GPT-4 32k', model: 'openai/gpt-4-32k' },
    { label: 'OpenAI: GPT-4 32k (older v0314)', model: 'openai/gpt-4-32k-0314' },
    { label: 'Nous: Hermes 13B', model: 'nousresearch/nous-hermes-llama2-13b' },
    { label: 'Mancer: Weaver (alpha)', model: 'mancer/weaver' },
    {
        label: 'Hugging Face: Zephyr 7B (free)',
        model: 'huggingfaceh4/zephyr-7b-beta:free'
    },
    {
        label: 'Anthropic: Claude v2.0 (self-moderated)',
        model: 'anthropic/claude-2.0:beta'
    },
    { label: 'Anthropic: Claude v2.0', model: 'anthropic/claude-2.0' },
    { label: 'ReMM SLERP 13B', model: 'undi95/remm-slerp-l2-13b' },
    {
        label: 'ReMM SLERP 13B (extended)',
        model: 'undi95/remm-slerp-l2-13b:extended'
    },
    { label: 'Google: PaLM 2 Chat', model: 'google/palm-2-chat-bison' },
    { label: 'Google: PaLM 2 Code Chat', model: 'google/palm-2-codechat-bison' },
    { label: 'MythoMax 13B (free)', model: 'gryphe/mythomax-l2-13b:free' },
    { label: 'MythoMax 13B', model: 'gryphe/mythomax-l2-13b' },
    { label: 'MythoMax 13B (nitro)', model: 'gryphe/mythomax-l2-13b:nitro' },
    { label: 'MythoMax 13B (extended)', model: 'gryphe/mythomax-l2-13b:extended' },
    { label: 'Meta: Llama 2 13B Chat', model: 'meta-llama/llama-2-13b-chat' },
    { label: 'Meta: Llama 2 70B Chat', model: 'meta-llama/llama-2-70b-chat' },
    { label: 'OpenAI: GPT-3.5 Turbo', model: 'openai/gpt-3.5-turbo' },
    { label: 'OpenAI: GPT-3.5 Turbo 16k', model: 'openai/gpt-3.5-turbo-0125' },
    { label: 'OpenAI: GPT-4', model: 'openai/gpt-4' },
    { label: 'OpenAI: GPT-4 (older v0314)', model: 'openai/gpt-4-0314' }
];

// export const OPEN_ROUTER_MODELS = open_router_models_arr_of_obj.map((item) => item.model);
